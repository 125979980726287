/**
 * main.ts
 * contains the whole logic of the project
 * uses classes that are defined 
 * in elements,components or layouts 
**/


var locations = null;

function GetURLParameter(sParam:any) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] == sParam) {
            return sParameterName[1];
        }
    }
}

/**
 * Document-ready function
**/

$(document).ready(function() {

    /**
     * Initalise function variables
    **/

   $('#contactsBundleZip').on('keyup', function() {
    var searchVal = $(this).val();
    var filterItems = $('.x-contacts__map-card');

    if ( searchVal.length > '1' ) {
        searchVal = searchVal.substr(0, 2);
        console.log(searchVal);
        filterItems.hide();
        $('[data-plz*="' + searchVal + '"]').show();
    } else {
        filterItems.show();
    }
    });


    
    /**
     * Basics
     * general scripts required for multiple components and elements
    **/

    let base = new Base();



    /**
     * Sidebar
    **/

    let sidebar = new Sidebar(); 


    /**
     * Content-Cols - same Header height script
    **/

   let contentcols = new ContentCols(); 



    /**
     * Flyout-nav
    **/

   let flyoutNav = new FlyoutNav();  



    /**
     * Cookie message
    **/

   let cookieMessage = new CookieMessage(); 



   /**
     * Forms
    **/

    // let form = new Formfloat();

    

    /**
     * Navigation
     * Parameters:
     * accordionMode: Close already open menu tabs
     * openCurrent: open active menu tab on load
     * animationDuration
     * overviewpagesPossible: Possiblity to open a menu point directly
    **/

    let panelnav = new Panelnav(true, true, 500, false); 

	

	/**
     * Landingpagenavigation
     * Parameters:
     * animationDuration
	**/

   	if($('.js-l-body').hasClass('js-l-body--landingpage')) {
		let landingpagenavigation = new Landingpagenavigation(); 
	}


    /**
     * To-Top-Button
     * Parameters:
     * animationDuration
    **/

    let totop = new toTop();



    /**
     * Header
    **/

   let header = new Header();



    /**
     * Pageloader
     * Parameters:
     * maximumTimeVisible
    **/

    let pageloader = new pageLoader();



    /**
     * Hero component
     * Parameters:
     * animationDuration
     * scrollFactor
    **/

    let hero = new Hero(600,0.7);
    

    
    /**
     * Slider
     * Parameters:
     * maximumTimeVisible
    **/

    let slider = new Slider();


    
    /* Watchlist */

	// let watchlist = new Watchlist();
	

	/**
	 * Filter for tables
	**/
	
    // let filter = new Filter();
    

});
/**
 * base.ts
 * 
 * 
**/


/* Declare google globally */

declare var Stickyfill: any; 
declare var ClipboardJS: any; 


class Base {

	/**
	 * Define class variables
	**/



	/**
	 * Constructor
	**/

	constructor () {

		/**
		 * Initalise function variables 
		**/

		let self = this;



		/**
		 * Call functions
		**/

		/* initTableSorter - JS-Lib for sortable tables */

		self.initTableSorter();


		/* initStickyElements - Stickyfill for several browsers */

		self.initStickyElements();
		

		/* picturefill */

		picturefill(); 


		/* Apply clipboard function */

		self.initClipboardElements();

		/* Watch events */

		self.watchEvents();
	}



	/**
	 * Function backLink():void
	 * creates / get a back link from document referrer or history
	**/

	protected backLink():void {
		$('.js-base__back-link').on('click', function() {
			let backURL = $(this).data('back-url');
			if (document.referrer.indexOf(window.location.host) !== -1) { history.go(-1); return false; } else { window.location.href = backURL; }
		});
	}



	/**
	 * Function anchorAnimation():void
	 * initialises / calls the tablesorter (external lib)
	**/

	protected anchorAnimation():void {

		/**
		 * Initalise function variables 
		**/

		let self = this;



		/**
		 * Listen on anchors with hashes
		**/

		$('.e-rte a[href^="#"]').on('click', function (event:any) {

			/* Prevent default behavior of anchors */

			event.preventDefault();


			/* Disabled if anchor has class backlink */

			if ($(this).hasClass('js-base__back-link'))
			return false;


			/* Get target of anchor elements */

			var target:any = $(this).attr('href');
			target = $(target);
			

			/* Scroll to target */

			$('html, body').stop().animate({
				scrollTop: target.offset().top - 200 }
			, 300);
		});

		if(window.location.hash) {
			var target:any = window.location.hash;
			target = $(target);
			
			$('html, body').stop().animate({
				scrollTop: target.offset().top - 200 }
			, 300); 
		}
	}



	/**
	 * Function initBootstrapPopover():void
	 * initialises / calls bootstrap popover
	**/

	protected initBootstrapPopover():void {

		/* Call popover on click */

		$('[data-toggle="popover"]').popover();


		/* Hide popover on click */

		$('.e-button__clipboard').click(function() {
			$('[data-toggle="popover"]').popover('hide');
			$(this).addClass('e-button__clipboard--copy');
			setTimeout(function() {
				$('.e-button__clipboard').removeClass('e-button__clipboard--copy');
			}, 2000);
		});
	}

	/**
	 * Function initTableSorter():void
	 * initialises / calls the tablesorter (external lib)
	**/

	protected initTableSorter():void {

		/* Set responsive class for each table */

		$('.e-rte table').each(function() {
			var tableContent = $(this).html();
			var tableID = $(this).attr('id');
			var tableClass = $(this).attr('class');
			$(this).replaceWith(function () {
				return $('<div class="table-responsive"><table id="' + tableID + '" class="' + tableClass + '">' + tableContent + '</table></div>');
			});
		});
	

		/* Call tablesorter function */

		$(".js-main__tablesorter").each(function() {
			let self:any = $(this);
			var rowCount = $('tr', this).length;
			if(rowCount >= 5) {
				self.tablesorter(); 
			}
		});
	}



	/**
	 * Function initStickyElements():void
	 * initialises stickyfill (external lib)
	**/

	protected initStickyElements():void {

		/* Apply the polyfill for sticky elements */
		
		var elements = $('.sticky');
		Stickyfill.add(elements);
	}




	/**
	 * Function initClipboardElements():void
	 * initialises Clipboard (external lib)
	**/

	protected initClipboardElements():void {

		/* Apply clipboard function to elements */

		let clipboard = new ClipboardJS('.e-button__clipboard');
	}



	/**
	 * Function formErrorScrollHandler():void
	 * scrolls to error
	**/

	protected formErrorScrollHandler():void {
		var error:any;
		$('*[type=submit]').on('click', function(){
			error = 0;
			$("input, textarea, select").focus(function() {
				if(error === 0){
					error = 1
	
					$('html, body').animate({
						scrollTop: $(this).offset().top - 200
					},'slow'); 
				}
			});
		})
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the base class
	**/

	protected watchEvents():void {

		/**
		 * Initalise function variables 
		**/

		let self = this;


		/**
		 * Call functions
		**/

		/* Backlink */

		self.backLink();


		/* AnchorAnimation */

		self.anchorAnimation();


		/* Bootstrap popover */

		self.initBootstrapPopover();


		/* From error scroll handler */

		self.formErrorScrollHandler();
	}
}
/**
 * autocomplete-zip.ts
 * 
 * 
**/

class AutocompleteZip {

	/**
	 * Define class variables
	**/



	/**
	 * Constructor
	**/

	constructor () {	
	}
	
	

	/**
	 * Function initAutocompleteZip():void
	 * Initialize autocomplete
	**/

	public initAutocompleteZip(id: any, isocodea2:any) { 

		var input = document.getElementById(id);

		
		/* Chrome bug */ 
		
			var observerHack = new MutationObserver(function() {
				observerHack.disconnect();
				$("#contactsBundleZip").attr("autocomplete", "new-password");
			});

			observerHack.observe(input, {
				attributes: true,
				attributeFilter: ['autocomplete']
			});
			

		var options = {
			componentRestrictions: {'country':isocodea2},
			/* cities */
			types: ['(regions)']
		};

		var autocomplete = new google.maps.places.Autocomplete(input,options);


		/* Add Event listener - for 'place changed' */

		google.maps.event.addListener(autocomplete, 'place_changed', function() {

			var location = autocomplete.getPlace();
			var geocoder = new google.maps.Geocoder();
			lat = location['geometry']['location'].lat();
			lng = location['geometry']['location'].lng();
			var latlng = new google.maps.LatLng(lat,lng);

			geocoder.geocode({'latLng': latlng}, function(results: any) {
				var political = '';
				var locality = '';
				var postal_code = '';
				var addressComponents = results[0].address_components;
				//console.debug(addressComponents);
				for (var i = 0; i < addressComponents.length; i++) {
					if (addressComponents[i].types[0] == "postal_code") postal_code = results[0].address_components[i].long_name;
					//if (addressComponents[i].types[0] == "locality") locality = results[0].address_components[i].long_name;
					//if (addressComponents[i].types[0] == "political") political = results[0].address_components[i].long_name;
				}
				//$('#'+id).val(postal_code + ((political!='' && political!=locality)?(' ' + political + ','):'') + ' ' + locality);
				$('#'+id).val(postal_code);
			});
		});

	}
}
/**
 * filter.ts
 * Filter, that automatically generates buttons and associated filter options
**/



class Filter {

	/**
	 * Initalise class variables
	**/

	public filter:JQuery;
	private activeFilter: JQuery;
	private validFilterResult: boolean;
	private lastFilterRemoved: any;

	/**
	 * Constructor
	 * call 'new Filter()'
	**/

	constructor () {
		
		/**
		 * Initalise local constructor variables
		**/
		
		let self = this;
		self.filter = $('.js-e-filter');
		self.activeFilter = self.filter.find('.e-filter__selection');
		self.validFilterResult = false;

		
		/**
		 * Set parameters
		**/

		// self.accordionMode = accordionMode;



		/**
		 * Call functions
		**/

		self.initializeFilter();
		self.watchEvents();
	}



	/**
	 * Function initializeFilter():void
	 * Initalize filter
	 * gets filterable values and generates a dynamic html for filter buttons
	**/

	public initializeFilter():void {

		/**
		 * Initalise function variables
		**/
		let self = this;
		let storedFilter:any = null;


		/* Initalize webstorage */

		if (typeof(Storage) !== "undefined") {
			// ?
		} else {
			// Sorry! No Web Storage support..
		}

		// self.filter.each((index, element) => {

			var generatedHTML = '';

			/**
			 * Get filterable values
			**/
			
			// let filterInstance = $(element);
			let filterInstance = self.filter;
			let tableHeader = filterInstance.next().find('table').find('thead').find('th');
			let tableRows = filterInstance.next().find('table').find('tbody').find('tr');
			var filterColumns:object = {}; 
			var filterColumnsIndexArray:any[] = []; 
			var filterValuesText:object = {}; 
			var filterValuesIds:object = {}; 
			let activeFilter:any = filterInstance.find('.e_filter__active-filter');
			
			tableHeader.each((index, element) => {
				let tableHeaderInstance = $(element);
				let name: string = tableHeaderInstance.data('filterName');
				let displayName: string = tableHeaderInstance.text();
				let filterIsActive:boolean = false;
				
				/* For all bricks / compontents without active filter selection */
				if(activeFilter.length == 0)
					filterIsActive = true;

				if(name) {

					activeFilter.each((index:number, element:HTMLElement) => {
						if(name == $(element).data('active-filter'))
							filterIsActive = true;
					});

					if(filterIsActive == true) {
						filterValuesText[index] = [];
						filterValuesIds[index] = [];
						filterColumns[index] = {};
						filterColumns[index]['index'] = index;
						filterColumns[index]['name'] = name;
						filterColumns[index]['displayName'] = displayName;
						filterColumnsIndexArray.push(index);
					}
				}
			});

			tableRows.each((index, element) => {
				let tableRowsInstance = $(element);
				let tableCells = tableRowsInstance.find('td');

				tableCells.each((index, element) => {
					if(jQuery.inArray(index,filterColumnsIndexArray) !== -1){

						let elementText = $(element).data('filterValues');

						if(elementText) {
							let elmentTextArray = elementText.split(',');

							$.each(elmentTextArray, function(index, element){
								elmentTextArray[index] = element.trim();
							});
	
							filterValuesText[index].push.apply(filterValuesText[index],elmentTextArray);
	
							let elementIds = $(element).data('filterIds').toString();
							let elmentIdsArray = elementIds.split(',');
	
							$.each(elmentIdsArray, function(index, element){
								elmentIdsArray[index] = element.trim();
							});
	
							filterValuesIds[index].push.apply(filterValuesIds[index],elmentIdsArray);
						}
					}
				});
			});
			

			/* Remove duplicated entries from array */

			for(let key in filterValuesText) {
				filterValuesText[key] = this.unique(filterValuesText[key]);
			}

			for(let key in filterValuesIds) {
				filterValuesIds[key] = this.unique(filterValuesIds[key]);
			}

			/**
			 * Build HTML
			**/

			var acceptText = filterInstance.find('.filter-placehoder').text();
			for(let key in filterColumns) {
				if(filterValuesText[key].length > 1){
					generatedHTML += '<div class="e-filter__buttons" data-filter-column="' + filterColumns[key]['name'] + '">';
					generatedHTML += '<button type="button" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">' + filterColumns[key]['displayName'] + '</button>';
					generatedHTML += '<div class="dropdown-menu e-form"><div class="dropdown-menu-scrollable">';
					
					for(let entry in filterValuesText) {
						if(entry === key) {
							 $.each(filterValuesText[entry], function (index, element) {
								generatedHTML += '<div class="e-form__checkbox-wrapper">';
								generatedHTML += '<input type="checkbox" name="check" data-filter-id="' + filterValuesIds[entry][index] + '" value="' + filterValuesText[entry][index] +'" id="check-' + filterColumns[key]['name'] + '-' + filterValuesIds[entry][index] +'">';
								generatedHTML += '<label for="check-' + filterColumns[key]['name'] + '-' + filterValuesIds[entry][index] +'">' + filterValuesText[entry][index] + '</label>';
								generatedHTML += '<span></span></div>'; 
							}); 
						}				
					}
					generatedHTML += '</div><button class="filter-apply">' + acceptText + '</button></div></div>	';
				}
			}
			filterInstance.find('.filter-placehoder').replaceWith(generatedHTML);
		// });

		/* Check if a filter for this page was already set */
		storedFilter = this.getLocalStore();

		if(storedFilter != null) {
			let minResult:boolean = false;

			this.filter.find('.e-filter__buttons').each(function(i, v) {
				var f: JQuery = $(v);
				var column: string = f.data('filter-column');
				var checkboxes: JQuery = f.find('input[type=checkbox]');


				/* Skip non existing filter options */

				if (!storedFilter[column] || $.isEmptyObject(storedFilter[column]) ) {
					return false;
				}


				checkboxes.each(function(i: number, c: HTMLInputElement) {
					var id: string = $(c).data('filterId') as string;
					if (storedFilter[column][id] == id ) {
						c.checked = true;
						minResult = true;
					}
				});
			});

			if(minResult) {
				/* Apply the filter once */

				this.applyFilters();
			}
		}
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the filter
	**/

	public watchEvents():void {

		/**
		 * Initalise function variables
		**/

		let self = this;



		/**
		 * Watch for clicks on filter dropdown menus 
		 * stops propagation
		**/

		self.filter.find('.dropdown-menu').on('click', function(e: JQueryEventObject) {
			e.stopPropagation();
		});



		/**
		 * Watch for clicks on filter apply buttons 
		 * search for checked "checkboxes"
		 * calls "applyFilters"
		**/

		self.filter.find('button.filter-apply').on('click', function() {
			var p: JQuery = $(this).parents('.e-filter__buttons');
			var selected: JQuery = p.find('input[type=checkbox]').filter(function(i: number, c: HTMLInputElement) { return c.checked; })
			p.data('selected', selected);

			self.applyFilters();
		});



		/**
		 * Restore checkbox state on close (to last applied)
		**/

		self.filter.find('.e-filter__buttons').on('show.bs.dropdown', function () {
			var selected: JQuery = $(this).find('input[type=checkbox]').filter(function(i: number, c: HTMLInputElement) { return c.checked; })
			$(this).data('selected', selected);
		});



		/**
		 * Resets checkbox states on close (to last applied)
		**/

		self.filter.find('.e-filter__buttons').on('hide.bs.dropdown', function () {
			var checkboxes: JQuery = $(this).find('input[type=checkbox]');
			var selected: JQuery = $(this).data('selected') as JQuery;

			checkboxes.each(function(i: number, checkbox: HTMLInputElement) {
				checkbox.checked = $(checkbox).is(selected);
			});
		});



		/**
		 * Watch for click on reset filter button
		 * resets all filter to unchecked state
		 * calls "applyFilters"
		**/

		self.activeFilter.find('button.filter-clear-all').on("click", function() {
			var checkboxes: JQuery = self.filter.find('input[type=checkbox]').each(function(i: number, c: HTMLInputElement) {
				c.checked = false;
			});
			self.applyFilters();
		});



		/**
		 * Watch for click on delete active filter
		 * Saves id for "lastFilterRemoved"
		**/

		self.filter.find('.e-filter__selection-error button').on('click', function() {
			if($(this).hasClass('filter-clear-all'))
				self.activeFilter.find('button.filter-clear-all').trigger('click');
			else
				self.resetLastFilter();
		});
	}



	/**
	 * Function addActiveFilter(text: string, checkbox: HTMLInputElement)
	 * Adds an active filter button below filter dropdowns
	**/

	public addActiveFilter(text: string, id: string, checkbox: HTMLInputElement) {
		var that: Filter = this;
		var existing: JQuery = this.activeFilter.find('button').filter(function() {
			return $(this).text() == text && $(this).data('filterId') == id;
		});

		if (existing.length === 0) {
			var button: JQuery = $('<button data-filter-id="' + id + '">').text(text);
			button.data('checkbox', checkbox);

			button.on("click", function() {
				var c: HTMLInputElement = $(this).data("checkbox");
				c.checked = false;
				/* Save last  */
				that.lastFilterRemoved = $(this).data('filter-id');
				that.applyFilters();
			});

			this.activeFilter.append(button);
			var textNode: Text = document.createTextNode(' ');
			this.activeFilter.append(textNode);
		}
		this.activeFilter.removeClass("d-none");
	}



	/**
	 * Function removeActiveFilter(text: string)
	 * Removes an active filter button below filter dropdowns
	**/

	public removeActiveFilter(text: string, id: string) {
		this.activeFilter.find('button').filter(function() {
			return $(this).text() == text && $(this).data('filterId') == id;
		}).remove();
		if (this.activeFilter.find('button').length == 1) {
			this.activeFilter.addClass("d-none");
		}
	}



	/**
	 * Function checkValidFilterResult()
	 * Checks if the result is of filtering is valid (>= one row as result)
	**/

	public checkValidFilterResult() {

		/**
		 * Initalise function variables
		**/

		let self = this;
		/* Filter result not valid - rerun filtering and delete all filters before*/
		if(self.validFilterResult == false) {
			self.filter.find('.e-filter__selection-error').removeClass("d-none");
			if(!self.lastFilterRemoved)
				self.filter.find('.e-filter__selection-error .filter-clear-last').addClass('d-none');
			else
				self.filter.find('.e-filter__selection-error .filter-clear-last').removeClass('d-none');
		} else {
			self.filter.find('.e-filter__selection-error').addClass('d-none');
		}
	}



	/**
	 * Function resetLastFilter()
	 * Readds the last removed filter
	**/

	public resetLastFilter() {
		let self = this;
		this.filter.find('.e-filter__buttons').each(function(i, v) {
			var f: JQuery = $(v);
			var checkboxes: JQuery = f.find('input[type=checkbox]');

			checkboxes.each(function(i: number, c: HTMLInputElement) {
				var id: string = $(c).data('filterId') as string;
				if (self.lastFilterRemoved == id ) {
					c.checked = true;
				}
			});
		});

		this.applyFilters();
	}

	/**
	 * Function setLocalStore(name: string, data: any)
	 * set a local storage
	**/

	public setLocalStore(data:any) {
		let filterName = $('html').data('page-id') + "-filter";
		let filterData = data;

		filterData = JSON.stringify(data);

		localStorage.setItem(filterName, filterData);
	}



	/**
	 * Function getLocalStore(name: string, data: any)
	 * set a local storage
	**/

	public getLocalStore():object {
		let filterName = $('html').data('page-id') + "-filter";

		if (localStorage.getItem(filterName) === null) {
			return null;
		} else {
			let storedFilter = localStorage.getItem(filterName);
			return JSON.parse(storedFilter);
		}
	}


	/**
	 * Function applyFilters(text: string)
	 * Applies the filter to the corresponding table values
	**/

	public applyFilters() {
		let table = this.filter.next().find('table');
		var that: Filter = this;
		var filters: object = {};
		var availableValues = {};
		that.validFilterResult = false;

		this.filter.find('.e-filter__buttons').each(function(i, v) {
			var f: JQuery = $(v);
			var column: string = f.data('filter-column');
			var checkboxes: JQuery = f.find('input[type=checkbox]');

			checkboxes.each(function(i: number, c: HTMLInputElement) {
				var value: string = $(c).val() as string;
				var id: string = $(c).data('filterId') as string;
				if (!filters[column]) { filters[column] = {}; }
				if (c.checked) {
					filters[column][id] = id;
					that.addActiveFilter(value, id, c);
				} else {
					delete filters[column][id];
					that.removeActiveFilter(value, id);
				}
			});
		});

		var columnMap: object = {};
		table.find('th').each(function(i, th) {
			var name: string = $(th).data('filterName');
			if (name) {
				columnMap[i] = name;
			}
		});

		table.find('tbody tr').each(function(i, tr) {
			var show: boolean = true;

			$(tr).find('td').each(function(i, td) {
				// filter not suitable, do not check other values
				if(show == false){
					return;
				}
				var column: string = columnMap[i];
				if(column) {
					// var text: string = $(td).text().trim();
					var elementIds = $(td).data('filterIds');
					if(elementIds) {
						elementIds = elementIds.toString();
						var elmentIdsArray = elementIds.split(',');
						let done = false;
						$.each(elmentIdsArray, function(index, element){
							if(done == true){
								return;
							}
							elmentIdsArray[index] = parseInt(element.trim());
							/* if filter column does not exists (maybe too few entries ) */
							if(typeof filters[column] === 'undefined')
								return;

							/* if elementID check is OK - stop the loop and go to next row */
							if( filters[column][elmentIdsArray[index]] && !$.isEmptyObject(filters[column])){
								show = true;
								done = true;
							}
							/* if elementID check is NOT OK - set visible to false and go to next ID of same element */
							else{
								if(!filters[column][elmentIdsArray[index]] && !$.isEmptyObject(filters[column])){
									show = false;
								}
							}
						});
					} else {
						show = false;
						if($.isEmptyObject(filters[column]))
							show = true;
					}
				} 
			});
			if (show) {
				$(tr).find('td').each(function(i, td) {
					var column: string = columnMap[i];
					// var text: string = $(td).text().trim();

					if (column) {
						var elementIds = $(td).data('filterIds');
						if(elementIds) {
							elementIds = elementIds.toString();
							var elmentIdsArray = elementIds.split(',');
							if (!availableValues[column]) availableValues[column] = [];
	
							$.each(elmentIdsArray, function(index, element){
								availableValues[column].push(parseInt(element.trim()));
								availableValues[column] = that.unique(availableValues[column]);
							});
						}
					}
				});
			}
			$(tr).toggle(show);
		});

		// disable checkboxes which would result in an empty table
		this.filter.find('.e-filter__buttons').each(function(i, v) {
			var f: JQuery = $(v);
			var column: string = f.data('filter-column');
			var checkboxes: JQuery = f.find('input[type=checkbox]');
			checkboxes.each(function(i: number, c: HTMLInputElement) {
				var value: string = $(c).val() as string;
				var id: string = $(c).data('filterId') as string;
				if (!availableValues[column] || $.isEmptyObject(filters[column]) ) {
				// if (!availableValues[column] || ($.isEmptyObject(filters[column]) && availableValues[column].indexOf(value) == -1)) {
					c.disabled = true;
					if($.inArray(id, availableValues[column]) >= 0) {
						c.disabled = false;
						that.validFilterResult = true;
					}
				} else {
					c.disabled = false;
					that.validFilterResult = true;
				}
			});
		});
		
		that.filter.find('.e-filter__buttons.show button.dropdown-toggle').dropdown('toggle');

		that.checkValidFilterResult();
		that.setLocalStore(filters);
	}
	


	/**
	 * Function unique(text: array)
	 * removes duplicate entries from a given array
	**/

	public unique(list:any) {
		var result:any = [];
		$.each(list, function(i, e) {
		  if ($.inArray(e, result) == -1) result.push(e);
		});
		return result;
	  }
}
/**
 * form-float.ts
 * 
 * 
 * 
 * 
 * 
 * !!!!!! THIS CLASS IS NOT IN USE !!!!
 * 
 * 
 * DATE: 13.12.2018
 *
 * 
 * 
 * 
 * 
 * 
 * 
**/

class Formfloat {

	/**
	 * Define class variables
	**/



	/**
	 * Constructor
	**/

	constructor () {

		/**
		 * Initalise function variables 
		**/

		let self = this;



		/**
		 * Call functions
		**/


		let floatSelect = $('.e-form__select');
		let floatFile = $('.e-form__file');
		let floatTextarea = $('.e-form__textarea textarea');
		let floatTel = $('.e-form__input-tel');

		floatTel.each(function() {
			let self = $(this);
			let selfCurrentInput = self.find('input[type=tel]');
			let selfHiddenInput = self.find('input[type=hidden]');
			selfCurrentInput.on('input', function() {
				let values = '';
				selfCurrentInput.each(function() {
					values += $(this).val();
				});
				selfHiddenInput.val(values);
			});

		});

		
		/* Select */

		floatSelect.each(function() {
			let self = $(this);
			let selfSelect = $('select', this);
			let selfSelectID = selfSelect.attr('id');
			let selfSelectName = selfSelect.attr('name');


			/* Add input with select ID */

			selfSelect.after('<input type="text" id="'+ selfSelectID +'" placeholder=" " readonly required>');
			selfSelect.after('<input type="hidden" id="'+ selfSelectID +'VALUE" placeholder=" " name="'+ selfSelectName +'" readonly required>');


			/* Add list for select options */

			self.append('<ul class="e-form__select-options"></ul>');

			let selfSelectInput = self.children('input[type="text"]');
			let selfSelectInputHidden = self.children('input[type="hidden"]');
			let selfSelectOptionsWrapper = self.children('.e-form__select-options');

			let selfSelectOptions = selfSelect.find('option');


			/* Fill List with content from select options */

			selfSelectOptions.each(function() {
				
				let selfSelectOptionsContent = $(this).html();
				let selfSelectOptionValue = $(this).attr('value');
				let selfSelectOptionsSelected = null;
				if($(this).is(':selected')) {
					selfSelectOptionsSelected = "active";	
					selfSelectInput.val(selfSelectOptionsContent);
					selfSelectInputHidden.val(selfSelectOptionValue);
				} else {
					selfSelectOptionsSelected = "";	
				}

				selfSelectOptionsWrapper.append('<li class="' + selfSelectOptionsSelected + '" data-value="'+selfSelectOptionValue+'">'+ selfSelectOptionsContent +'</li>');
			});


			/* click on select group open List with options */

			self.on('mousedown touchstart', function () {
				if(selfSelectOptionsWrapper.is(":hidden")) {
					$(this).addClass('select-open');
				}
			});


			/* click on list - write selected option in input and close list */

			self.find('li').on('click', function() {
				let selfSelectOptionsValue = $(this).data("value");
				selfSelectInput.val($(this).html());
				selfSelectInputHidden.val(selfSelectOptionsValue);
				floatSelect.removeClass('select-open');
				$('.e-form__select-options li').removeClass('active');
				$(this).addClass('active');
			});


			/* original select remove because duplicate id */

			selfSelect.remove();
		});


		/* File */

		floatFile.each(function() {
			let self = $(this);
			let selfFile = $('input[type="file"]', this);
			let selfFileID = selfFile.attr('id');


			/* Add input with file ID */

			selfFile.after('<input type="text" placeholder=" " disabled class="e-form__file-new">');
			let selfFileNew = self.children('.e-form__file-new');


			/**
			 * 
			 * 
			 * 
			 * 
			 *  !!! REFACTOR IF IN USE !!!
			 * 
			 * 
			 * 
			 * 
			 * 
			**/
			/* Fill fake file-input with file-name */
			// selfFile.bind('change',function(){
			// 	$(this).blur();
			// 	var names = [];
			// 	for (var i = 0; i < $(this).get(0).files.length; ++i) {
			// 		names.push($(this).get(0).files[i].name);
			// 	}
			// 	selfFileNew.val(names); 
			// });
		});

		
		/* Textarea autoresize */

		floatTextarea.each(function() {
			var offset = this.offsetHeight - this.clientHeight;

			var resizeTextarea = function(el:any) {
				$(el).css('height', 'auto').css('height', el.scrollHeight + offset);
			};
			$(this).on('keyup input', function() { resizeTextarea(this); });
		});
	}
}
/**
 * to-top.ts
 * Logic of to-top button on page end
 * 
**/

class Header {

	/**
	 * Constructor
	**/

	constructor () {

		/**
		 * Initalise local constructor variables
		**/

		let self = this;



		/**
		 * Call functions
		**/

		/* Toggle small header */

		this.toggleSmallHeader();
		

		/* Watch events */

		this.watchEvents();
	}



    /**
     * Function toggleSmallHeader
    **/

    protected toggleSmallHeader () {
        let target = window;
		let documentHtml = $('html');
		
		// get scroll position based for all browsers
		let scrollPos  = target.pageYOffset || target.scrollY || $("body").scrollTop;
    
		if(scrollPos > 20) {
			if(!documentHtml.hasClass('js-l-header--small')) {
				documentHtml.addClass('js-l-header--small');
			}
		} else {
			if(documentHtml.hasClass('js-l-header--small')) {
				documentHtml.removeClass('js-l-header--small'); 
			}
		}
    }



	/**
	 * Function watchEvents():void
	 * Watches all events of the navigation class
	**/

	protected watchEvents():void {

		/**
		 * Initalise function variables 
		**/

		let self = this;



		/* Toggle HTML class' - * if page is scrolled */

		$(window).on('scroll',function () {
			self.toggleSmallHeader();
		});
	}
}
/**
 * navigation.ts
 * Logic of navigation
**/



class Landingpagenavigation {

    /**
     * Initalise class variables
    **/

    public animationDuration:number;
    

    
    /**
     * Constructor
    **/

    constructor (animationDuration: number=1000) {

        /**
         * Initalise local constructor variables
        **/
       
        let self = this;



        /**
         * Set parameters
        **/

		self.animationDuration = animationDuration;



        /**
         * Call functions
        **/
	   
        self.initializeMenu();
        self.watchEvents();
    }


   /**
     * Function initializeMenu():void
     * Initalize menu
     * Create HTML elements for landingpagenavigation
    **/

   public initializeMenu():void {

        /**
         * Initalise function variables
        **/

        let self = this;



        /**
         * Build navigation-frame for header- and burgernav 
        **/

        $('.js-landingpage__topic-nav').html('<ul class="l-topic-nav"></ul>');
        $('.js-landingpage__panel-nav ').html('<ul class="l-pagetree-panel"></ul>');



        /**
         * Build navigation-points for header- and burgernav 
        **/

        $('main section').each((index, element) => {
            if($(element).attr('id') != null){
                $('.l-topic-nav').append('<li><a href="#' + $(element).attr('id') + '">' + $(element).data('anchor-title') + '</a></li>');
                $('.l-pagetree-panel').append('<li><a href="#' + $(element).attr('id') + '">' + $(element).data('anchor-title') + '</a></li>');
            }
		});
		

		/* Set active state of menu-items */

        self.setActive();
    }


    
    /**
     * Function watchEvents():void
     * Watches all events of the landingpagenavigation and handles scrolling 
    **/

    public watchEvents():void {

        /**
         * Initalise function variables
        **/

        let self = this;


        
        /* Watch click on menu-items */

        $('.js-landingpage__topic-nav li a, .l-pagetree-panel li a').on('click',function(event){
            let anchor = $(event.target).attr('href');
            let anchorScrollTop = $(anchor).offset().top;
			let anchorScrollPosition =  anchorScrollTop - 150;
			
			if($('.js-l-body').hasClass('js-l-panel-nav--open')) {
				$('.l-panel-nav__backdrop').trigger('click');
			}

            $('html').stop().animate({
                scrollTop: anchorScrollPosition }, 300);
            event.preventDefault();
		});
		


		/* Watch scrolling of document for setting active state */

        $(window).on('scroll',function () {
            self.setActive();
        });
    } 



    /**
     * Function setActive():void
     * Set active state of menu-items
    **/
    
    public setActive():void {
		$('.js-landingpage__topic-nav li a, .l-pagetree-panel li a').each((index, element) => {
			let anchor = $(element).attr('href');
            let anchorScrollTop = $(anchor).offset().top;
            let parentItem = $(element).parent('li');
            let scrollCheckPoint = $(window).scrollTop() + 166;


            if(scrollCheckPoint >  (anchorScrollTop + $(anchor).outerHeight(true) ) ) {
                // Do nothing
            }
            else {
                if(anchorScrollTop < scrollCheckPoint)
                    parentItem.addClass('active').prev().removeClass('active');
                else 
                    parentItem.removeClass('active');
            }
        });
    }
}
/**
 * content-cols.ts
 * 
 * 
**/

class ContentCols {

	/**
	 * Define class variables
	**/



	/**
	 * Constructor
	**/

	constructor () {

		/**
		 * Initalise function variables 
		**/

		let self = this;



		/**
		 * Call functions
		**/

		/* Watch events */

		self.watchEvents();
	}



	/**
	 * Function initContentCols():void
	 * initialises content col elements (brick)
	**/

	protected initContentCols():void {
		$('.c-content-cols').not('.editmode .c-content-cols').each(function() {
        
            var $list = $(this),
                $items = $list.find('.c-content-cols__headline');
    
            $items.css( 'height', 'auto' );
    
            var maxHeight = 0;
            $items.each( function()
            {
                var itemHeight = Number($( this ).outerHeight());
				if (itemHeight > maxHeight) 
					maxHeight = itemHeight;
                if (itemHeight == 1) 
                    $(this).addClass('no-height');
                
            });
            $items.each(function(){
    
                if(!$(this).hasClass('no-height')) {
                    $(this).css('height', maxHeight);
                }
            });
        }); 
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the base class
	**/

	protected watchEvents():void {

		/**
		 * Initalise function variables 
		**/

		let self = this;
		self.initContentCols(); 

		/**
		 * Call functions
		**/

		/* */
	}
}
/**
 * slider.ts
 * 
 * 
**/

class Slider {

	/**
	 * Define class variables
	**/



	/**
	 * Constructor
	**/

	constructor () {

		/**
		 * Initalise function variables 
		**/

		let self = this;



		/**
		 * Call functions
		**/

		/* SlickSlider initialize - add several classes */

		$('.js-slider__page').each(function() {
			$(this).on('init', function(event, slick){
				var modalID = $(".slick-current", this).data("target");
				$(this).append('<span class="c-slider__modal-open" data-toggle="modal" data-target="'+ modalID +'"></span>');
			});
		});


		/* Initalize slick slider */

		self.initSlickSlider();


		/* Watch events */

		self.watchEvents();
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the FlyoutNav class
	**/

	protected initSlickSlider():void {

		/**
		 * Initalise function variables 
		**/

		let self = this;


		/**
		 * Call functions
		**/

		/* Initialize modals */

		$('.js-slider__modal').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			asNavFor: '.js-slider__page',
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			customPaging : function(slider, i) {
				return '';
			}
		});


		/* Initialize slider */

		$(".js-slider__page").slick({
			dots: true,
			asNavFor: '.js-slider__modal',
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			customPaging : function(slider, i) {
				return '';
			}
		});
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the FlyoutNav class
	**/

	protected watchEvents():void {

		/**
		 * Initalise function variables 
		**/

		let self = this;


		/**
		 * Call functions
		**/

		/* Watch modal show */

		$('.c-slider__modal').on('shown.bs.modal', function (e) {
			$('.js-slider__modal').slick('refresh');
			setTimeout(function(){
				$('.js-slider__modal').css("visibility", "visible");
			}, 200);
		});
	}
}
/**
 * contacts.ts
 * 
 * 
**/

class Contacts {

	/**
	 * Define class variables
	**/



	/**
	 * Constructor
	**/

	constructor () {
	}



	/**
	 * Function initContacts():void
	 * Initialize contacts
	**/

    public initContacts(locationsArray:any, path:any) {

		/**
		 * Initalise function variables 
		**/

		let self = this;


		/* Add event handler */

		$('#contactsBundleCountrySelect').on("change", function() {
			let selectedCountry = $(this).val();
			self.showZip(selectedCountry, locationsArray);
		})

		$( "#js-x-contact__search-button" ).on( "click", function() {
			self.showContact(path);
			return false;  
		});

		self.showContact(path);

		var parameterCountry = GetURLParameter('country');
		self.showZip(parameterCountry, locationsArray);

	}	



	/**
	 * Function showContact():void
	 * 
	**/

	public showContact(path:any) {
		var str = $( "form" ).serialize();
		var url = path + str
		$.ajax({
			url: url,
			success: function( data ) {
				$( "#js-x-contact__result" ).html(data);
			}
		});
	}



	/**
	 * Function showZip():void
	 * 
	**/

	public showZip(country:any, locationsArray:any) {
		if (jQuery.inArray(country, locationsArray) != -1) {
			$('#js-x-contact__search-zip').show();  
			var autocompleteZip = new AutocompleteZip();
			autocompleteZip.initAutocompleteZip('contactsBundleZip', country); 
		} else {
			$('#js-x-contact__search-zip').hide();    
		}    
	}



}
/**
 * distributor.ts
 * 
 * 
**/

class Distributor {

	/**
	 * Define class variables
	**/



	/**
	 * Constructor
	**/

	constructor () {
	}



	/**
	 * Function initContacts():void
	 * Initialize contacts
	**/

    public initDistributor(path:any) {

		/**
		 * Initalise function variables 
		**/

			let self = this;


			/* Add event handler */

			$( "#js-x-distributor__search-button" ).on( "click", function() {
				self.showDistributor(path);
				return false;
			});

			$('#js-x-distributor__reset-button').click(function(e) {
				e.preventDefault();

				$('#distributorBundleZip').val("");

				$(this).parents('form').find("input[type='checkbox']").prop('checked', false);

				self.showDistributor(path);
				return false;
			});

			var autocompleteZip = new AutocompleteZip();
			autocompleteZip.initAutocompleteZip('distributorBundleZip', 'DE');

			self.showDistributor(path);
	}



	/**
	 * Function showContact():void
	 * 
	**/

	public showDistributor(path:any) {
		var str = $( "form" ).serialize();
		var url = path + str
		$.ajax({
			url: url,
			success: function( data ) {
				$( "#js-x-distributor__result" ).html(data);
			}
		});
	}
}
/**
 * locations.ts
 * 
 * 
**/


/* Declare google globally */

declare var google: any; 
declare var lat: any; 
declare var lng: any; 
declare var bounds: any; 


class Locations { 

	/**
	 * Define class variables
	**/

	public markers:any = [];



	/**
	 * Constructor
	**/

	constructor () {	
	}



	/**
	 * Function setMarkers():void
	 * Set markers of map instance
	**/

	public setMarkers(map:any, locations:any) {
		
		/**
		 * Initalise function variables 
		**/

		let self = this;


		/* Do for every location */

		for (var i = 0; i < locations.length; i++) {
			var location = locations[i];
			var infoContent = location[5];
			var image = {
				url: location[4]
			};

			var locationInfowindow = new google.maps.InfoWindow({
				content: infoContent,
				maxWidth: 300
			});

			var marker = new google.maps.Marker({
				position: {lat: location[1], lng: location[2]},
				map: map,
				icon: image,
				zIndex: location[3],
				title: location[0],
				infowindow: locationInfowindow
			});

			self.markers.push(marker);

			google.maps.event.addListener(marker, 'click', function() {
				self.hideAllInfoWindows(map);
				this.infowindow.open(map, this);
			});

			var loc = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
			bounds.extend(loc);
		}

		map.fitBounds(bounds);
		map.panToBounds(bounds);
		map.setOptions({maxZoom:16});
	}



	/**
	 * Function hideAllInfoWindows():void
	 * Hides information window of map instance
	**/

	public hideAllInfoWindows(map:any) {
		
		/**
		 * Initalise function variables 
		**/

		let self = this;


		/* Close every info window */

		self.markers.forEach(function(marker:any) {
			marker.infowindow.close(map, marker);
		}); 
	}


	/**
	 * Function initMap():void
	 * Initalize google map instance
	**/

	public initMap(mapid:any, locations:any) {

		/**
		 * Initalise function variables 
		**/

		let self = this;


		/* Create a map instance with several options */

		var map = new google.maps.Map(document.getElementById(mapid), {
			zoom: 15,
			center: {lat: 0.0, lng: 0.0 },
			streetViewControl: false,
			styles: [
				// modify style: https://mapstyle.withgoogle.com/
				{
					"elementType": "geometry",
					"stylers": [
					{
						"color": "#f5f5f5"
					}
					]
				},
				{
					"elementType": "labels.icon",
					"stylers": [
					{
						"visibility": "off"
					}
					]
				},
				{
					"elementType": "labels.text.fill",
					"stylers": [
					{
						"color": "#616161"
					}
					]
				},
				{
					"elementType": "labels.text.stroke",
					"stylers": [
					{
						"color": "#f5f5f5"
					}
					]
				},
				{
					"featureType": "administrative.land_parcel",
					"elementType": "labels.text.fill",
					"stylers": [
					{
						"color": "#bdbdbd"
					}
					]
				},
				{
					"featureType": "poi",
					"elementType": "labels",
					"stylers": [
					{
						"visibility": "off"
					}
					]
				},
				{
					"featureType": "road",
					"elementType": "geometry",
					"stylers": [
					{
						"color": "#ffffff"
					}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels.text.fill",
					"stylers": [
					{
						"color": "#757575"
					}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "geometry",
					"stylers": [
					{
						"color": "#dadada"
					}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "labels.text.fill",
					"stylers": [
					{
						"color": "#616161"
					}
					]
				},
				{
					"featureType": "road.local",
					"elementType": "labels",
					"stylers": [
					{
						"visibility": "off"
					}
					]
				},
				{
					"featureType": "road.local",
					"elementType": "labels.text.fill",
					"stylers": [
					{
						"color": "#9e9e9e"
					}
					]
				},
				{
					"featureType": "transit.line",
					"elementType": "geometry",
					"stylers": [
					{
						"color": "#e5e5e5"
					}
					]
				},
				{
					"featureType": "transit.station",
					"elementType": "geometry",
					"stylers": [
					{
						"color": "#eeeeee"
					}
					]
				},
				{
					"featureType": "water",
					"elementType": "geometry",
					"stylers": [
					{
						"color": "#c9c9c9"
					}
					]
				},
				{
					"featureType": "water",
					"elementType": "labels.text.fill",
					"stylers": [
					{
						"color": "#9e9e9e"
					}
					]
				}
			]
		});

		bounds = new google.maps.LatLngBounds();

		self.setMarkers(map, locations);
	}
}
// class Watchlist {
//     constructor () {

// 		var watchlistFunctions = new WatchlistFunctions('/rest-api/product-notes/', function(success: boolean, product: any, add: number) {

// 			var buttons = $('.x-watchlist__button');		
// 			buttons.removeAttr('disabled');

// 			if (success) {
// 				var note: JQuery = $('.l-sidebar__watchlist');
// 				var content: JQuery = note.find('.l-sidebar__content');
// 				var emptyMessage: JQuery = note.find('.l-sidebar__watchlist--empty');
// 				var badge: JQuery = note.find('.l-sidebar__badge');
// 				var badgeText: number = parseInt(badge.text(), 10);

// 				badge.text(badgeText + add);
// 				for(var i=0;i<3;i++) {
// 					note.fadeOut(250).fadeIn(250);
// 				}
	
// 				var buttonAdd: JQuery = buttons.filter('.add');
// 				var buttonDelete: JQuery = buttons.filter('.delete');

// 				if (add === 1) {
// 					note.removeClass('d-none');
// 					var newItem: JQuery = $('<li>').text(product.name); 
// 					content.find('ul').append(newItem);
// 					note.removeClass('d-none');
// 					buttonAdd.addClass('d-none');
// 					buttonDelete.removeClass('d-none');
// 					badge.removeClass('d-none');
// 					emptyMessage.addClass('d-none');
// 				} else if (add === -1) {
// 					content.find('li').filter(function() {
// 						return $(this).text() == product.name;
// 					}).remove();

// 					// $('#uid-heitec-products-product-watchlist').find('tr').filter(filterFunc).remove();
// 					// $('.x-products__watchlist-products').find('.x-products__watchlist-product').filter(filterFunc).remove();
					
// 					buttonAdd.removeClass('d-none');
// 					buttonDelete.addClass('d-none');

// 					changeDisabled();

// 					if ($('.x-products__watchlist').length > 0 && $('.x-products__watchlist-products').children().length == 0) {
// 						$('.x-products__watchlist-form').addClass('d-none');
// 						$('.x-products__watchlist-empty').removeClass('d-none');
// 					}

// 					if (badge.text() === "0") {
// 						badge.addClass('d-none');
// 						emptyMessage.removeClass('d-none'); 
// 						note.addClass('d-none');
// 					}
// 				}
// 			} else {

// 				if (add === 1) {
// 					alert('Das Produkt konnte nicht zur Merkliste hinzugefügt werden.');
// 				} else if (add === -1) {
// 					//$(this).parents('.x-products__watchlist-product').filter(filterFunc).removeClass('d-none');
// 					alert('Das Produkt konnte nicht entfernt werden, vermutlich befindet es sich bereits nicht mehr auf ihrer Merkliste.');
// 				}
// 			}
// 		});
// 		$('.x-watchlist__button').on('click', function() {
// 			console.log("click");
// 			if ($(this).is('.disabled')) { return false; }
// 			var productNr: string = $(this).data('product-nr');
// 			var productName: string = $(this).data('product-name');
// 			if ($(this).is('.add')) {
// 				watchlistFunctions.put({'nr': productNr, 'name': productName});
// 			} else if ($(this).is('.delete')) {
// 				watchlistFunctions.delete({'nr': productNr, 'name': productName});
// 			}
// 			$(this).attr('disabled', 'disabled');
// 		});
// 		$('.js-x-products__watchlist__delete').on('click', function() {
// 			$('.js-x-products__watchlist--choose:checked').each(function() {
// 				var productNr = $(this).val(),
// 				productName = $(this).parents('.x-products__watchlist-content').find('h5').text();
// 				watchlistFunctions.delete({'nr': productNr, 'name': productName});
// 				$(this).parents('.x-products__watchlist-product').addClass('d-none');
// 			});
// 		});


// 		var deleteTitle = $('.js-x-products__watchlist__delete').attr("title");
// 		var compareTitle = $('.js-x-products__watchlist__compare').attr("title");

// 		function changeDisabled() {
// 			var i = 0;
// 			$('.js-x-products__watchlist--choose').each(function() {
// 				var thisProp = $(this).prop('checked');
// 				if(thisProp == true) {
// 					i++;
// 				} 	
// 			});
// 			if (i >= 1) {
// 				console.log(i);
// 				$('.js-x-products__watchlist__delete').removeAttr("disabled").attr("title", '');     
// 			} else {
// 				$('.js-x-products__watchlist__delete').attr("disabled", "disabled").attr("title", deleteTitle);
// 			}
// 			if (i >= 5) {
// 				$('.js-x-products__watchlist__compare').attr("disabled", "disabled").attr("title", compareTitle);  
// 			} else if (i >= 2) {
// 				$('.js-x-products__watchlist__compare').removeAttr("disabled").attr("title", '');    
// 			} else {
// 				$('.js-x-products__watchlist__compare').attr("disabled", "disabled").attr("title", compareTitle);
// 			} 
			
// 		}

// 		$('.js-x-products__watchlist--all').on('click', function() {
// 			var thisProp = $(this).prop('checked');
// 			if(thisProp == true) {
// 				$('.js-x-products__watchlist--choose').prop('checked', true);
// 			} else {
// 				$('.js-x-products__watchlist--choose').prop( "checked", false );
// 			}
// 			changeDisabled()
// 		});
		
// 		$('.js-x-products__watchlist--choose').change(function() {
// 			changeDisabled()
// 			$('.js-x-products__watchlist--all').prop( "checked", false );
// 		}); 
		
//     }
// }
// class WatchlistFunctions {

// 	private url: string;
// 	private cb: Function;

//     constructor(url: string, callback: Function) {
// 		if (url.lastIndexOf('/') != url.length - 1) { url += '/'; }
// 		this.url = url;
// 		this.cb = callback;
// 	}

// 	public put(product: any) {
// 		this.doRequest("put", product);
// 	}

// 	public delete(product: any) {
// 		this.doRequest("delete", product);
// 	}

// 	private doRequest(method: string, product: any) {
// 		var that = this;
// 		var add: number = method == "put" ? 1 : -1;
// 		$.ajax({
// 			'url': this.url+product.nr,
// 			'dataType': 'json',
// 			'method': method,
// 			'success': function(data: any) { that.onSuccess(data, product, add); },
// 			'error': function() { that.onError(product, add); }
// 		});
// 	}
// 	private onSuccess(data: any, product: any, add: number) {
// 		if (data && data.success) {
// 			this.cb(true, product, add);
// 		} else {
// 			this.cb(false, product, add);
// 		}
// 	}
// 	private onError(product: any, add: number) {
// 		this.cb(false, product, add);
// 	}
// }
/**
 * hero.ts
 * Logic of hero component
**/



class Hero {

	/**
	 * Initalise class variables
	**/

	public animationDuration:number;
	public scrollFactor:number;



	/**
	 * Constructor
	**/

	constructor (animationDuration: number=1000, scrollFactor: number=0.7) {

		/**
		 * Initalise local constructor variables
		**/
		
		let self = this;



		/**
		 * Set parameters
		**/

		self.animationDuration = animationDuration;
		self.scrollFactor = scrollFactor;



		/**
		 * Call functions
		**/

		self.watchEvents();
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the hero component and handles scrolling 
	**/

	public watchEvents():void {

		/**
		 * Initalise function variables
		**/

		let self = this;



		/* Watch scroll event for text-box */
		
		$(window).scroll((e) => {
			this.parallax();
		});


		/* Watch click event on CTO button */

		$('.js-hero__scroll').on('click', (e) => {
			let headerHeight = $('.js-l-hero__header').height();
			$('html, body').stop().animate({
				scrollTop: $('.pimcore_hero').next().offset().top - headerHeight }, this.animationDuration);
			event.preventDefault();
		});
	} 



	/**
	 * Function parallax():void
	 * Set margin-top to text-box (small parallax effect)
	**/

	public parallax():void {

		/**
		 * Initalise function variables
		**/

		let self = this;
		let scrolled = $(window).scrollTop();


		/* Set margin of text-box */

		$('.l-hero__design--full .l-hero__text').css('margin-top',(scrolled*this.scrollFactor)+'px');
	}
}
/**
 * panel-menu.ts
 * Panel menu with an accordion mode und current opening submenu
 * 
**/



class PagetreePanel {

	/**
	 * Initalise class variables
	**/

	public accordionMode:any;
	public openCurrent:any;
	public animationDuration:any;
	public overviewpagesPossible:any;
	public menu:any;



	/**
	 * Constructor
	 * call 'new Panelmenu(true, true, 500, false)'
	**/

	constructor (accordionMode:boolean=true,openCurrent:boolean=true,animationDuration: number=1000,overviewpagesPossible :boolean=false) {
		
		/**
		 * Initalise local constructor variables
		**/
		
		let self = this;
		self.menu = $('.js-l-pagetree-panel');


		
		/**
		 * Set parameters
		**/

		self.accordionMode = accordionMode;
		self.openCurrent = openCurrent;
		self.animationDuration = animationDuration;
		self.overviewpagesPossible = overviewpagesPossible;



		/**
		 * Call functions
		**/

		/* Initialize Menu */

		self.initializeMenu();


		/* Watch events */

		self.watchEvents();
	}



	/**
	 * Function initializeMenu():void
	 * Initalize menu
	 * Set classes for open-states to elements
	**/

	public initializeMenu():void {

		/**
		 * Initalise function variables
		**/

		let self = this;



		/**
		 * Set additonal info classes to elements
		 * add toggle-class to main link only if overviewpages are possible
		**/

		self.menu.find('li').has('ul').addClass('l-pagetree-panel__parent').children('a')
			.append('<span class="js-l-pagetree-panel__parent--toggle l-pagetree-panel__parent--toggle"></span>');

		if (self.overviewpagesPossible == false){
			self.menu.find('li').has('ul').children('a').addClass('js-l-pagetree-panel__parent--toggle').children('.l-pagetree-panel__parent--toggle')
				.removeClass('js-l-pagetree-panel__parent--toggle');
		}

		self.menu.find('li.l-pagetree-panel__item--active').has('ul').addClass('l-pagetree-panel__item-parent--active');
		


		/**
		 * Set open-state to elements
		**/

		if(self.openCurrent == true) {
			$('li.js-l-pagetree-panel__item--active').each(function() {
				if ($(this).children('a').hasClass('active-trail')){
					$(this).addClass('js-l-pagetree-panel__item-parent--open');
				}
			});
			self.menu.find('li.js-l-pagetree-panel__item-parent--open').has('ul').children('ul').slideDown();
		}  
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the panelmenu and handles toggeling 
	**/

	public watchEvents():void {

		/**
		 * Initalise function variables
		**/

		let self = this;
		let toggleButton = $('.js-l-pagetree-panel__parent--toggle');



		/**
		 * Watch for clicks on menu-parent item and 
		 * handle toogling of menus and submenus
		**/

		toggleButton.on('click touchend', function(e:any){
			let currentMenu = $(this).closest('li');
			e.preventDefault();



			/**
			 * Handling of click on already opened menu
			 **/

			if(currentMenu.hasClass('js-l-pagetree-panel__item-parent--open')){
				currentMenu.removeClass('js-l-pagetree-panel__item-parent--open')
					.children('ul').stop().slideUp(self.animationDuration)
					.find('li.js-l-pagetree-panel__item-parent--open')
					.removeClass('js-l-pagetree-panel__item-parent--open')
					.children('ul').stop().slideUp(self.animationDuration);
			} else {



				/**
				 * If accordion mode is active close all other panels in menu
				 * unless clicked menu is submenu of already opend 
				**/
				
				if(self.accordionMode) {
					if(!currentMenu.parents().hasClass('js-l-pagetree-panel__item-parent--open')){
						self.menu.find('li.js-l-pagetree-panel__item-parent--open')
						.removeClass('js-l-pagetree-panel__item-parent--open')
						.find('ul').stop().slideUp(self.animationDuration);
					}
				}



				/**
				 * Open clicked menu
				**/
				
				currentMenu.addClass('js-l-pagetree-panel__item-parent--open').children('ul').stop().slideToggle(self.animationDuration);
			}
		});
	}
}
/**
 * panel-menu.ts
 * Panel menu with an accordion mode und current opening submenu
 * 
**/



class PagetreeSub {

	/**
	 * Define class variables
	**/


	/**
	 * Constructor
	**/
	constructor () {
			
		this.watchEvents(); 

	}



	/**
	 * Function toggleNav():void
	 *
	**/

	protected toggleNav():void {
		$('.js-l-pagetree-sub__opener').on('click touchend', function (e) {
			let subNavID = $(this).data('subnav-primary');

			$('.js-l-pagetree-sub__opener').removeClass('l-pagetree-sub__item--open');
			$(this).addClass('l-pagetree-sub__item--open');
			$('.l-pagetree-sub__secondary').hide();
			$('*[data-subnav-secondary="' + subNavID + '"]').show();

			return false;
		});
	}



	/**
	 * Function activeNav():void
	 *
	**/

    protected activeNav():void {
        $('.js-l-pagetree-sub__opener').each(function (e) {
            if($(this).hasClass('l-pagetree-sub__item--active')) {
                let subNavID = $(this).data('subnav-primary');
                $('*[data-subnav-secondary="' + subNavID + '"]').show();   
            }
        });
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the base class
	**/

	protected watchEvents():void {

		/**
		 * Initalise function variables 
		**/

		let self = this;


		/**
		 * Call functions
		**/

		self.toggleNav();
		self.activeNav();
		

		/* Watch navigation opener */

		$('.js-l-flyout-nav__opener').on('click touchend', function (e) {
			$('.l-pagetree-sub__secondary').hide();
			$('.js-l-pagetree-sub__opener').removeClass('l-pagetree-sub__item--open');
			
			self.activeNav();
		});
	}
}

$(document).ready(function() {

});
/**
 * cookie-message.ts
 * 
 * 
**/

class CookieMessage {

	/**
	 * Define class variables
	**/


	/**
	 * Constructor
	**/
	constructor () {

		/**
		 * Initalise function variables 
		**/

		let self = this;



		/**
		 * Call functions
		**/

		/* Watch events */

		self.watchEvents();
	}



	/*
	* Function showMessage
	*/

	protected showMessage():void {
		if(document.cookie.indexOf('hideCookieMessage=1') != -1){
			$('.l-cookie-message').addClass('close');
		}
		else{
			$('.l-cookie-message').removeClass('close');
		} 
	}



	/*
	* Function closeMessage
	*/

	protected closeMessage():void {
		$('.l-cookie-message__button-ok').on('click', function() {
			$(this).parents('.l-cookie-message').addClass('close'); 
			document.cookie = 'hideCookieMessage=1';
			return false;
		}); 
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the FlyoutNav class
	**/

	protected watchEvents():void {

		/**
		 * Initalise function variables 
		**/

		let self = this;


		/**
		 * Call functions
		**/

		/* Show cookie message */

		self.showMessage();


		/* Close cookie message */

		self.closeMessage();
	}
}
/**
 * flyout-nav.ts
 * 
 * 
**/

class FlyoutNav {

	/**
	 * Define class variables
	**/



	/**
	 * Constructor
	**/
	constructor () {

		/**
		 * Initalise function variables 
		**/

		let self = this;



		/**
		 * Call functions
		**/

		/* Watch events */

		self.watchEvents();



		/* Get instance of PagetreeSecondary */

		let pagetreeSub = new PagetreeSub(); 
	}



	/**
	 * Function toggleNav():void
	 * 
	**/

	protected toggleNav():void {
		$('.js-l-flyout-nav__opener').on('click touchend', function (e) {
			// console.log('open');
			let mainNavID = $(this).data('mainnav-main'); 
			let mainNavID2 = 'navID' + mainNavID;
			// console.log(mainNavID);

			$('.l-pagetree-sub').hide();
			$('*[data-mainnav-sub="' + mainNavID + '"]').show();

			if($('.js-l-flyout-nav').hasClass('l-flyout-nav__open')) {
				let openID = $('.js-l-flyout-nav').attr('id');

				if (openID == mainNavID2) {
					$('.js-l-flyout-nav').removeClass('l-flyout-nav__open'); 
					$(this).removeClass('l-pagetree-main__item--open'); 
				} else {
					$('.js-l-flyout-nav').removeClass('l-flyout-nav__open');
					$('.js-l-flyout-nav__opener').removeClass('l-pagetree-main__item--open'); 
					
					$('.js-l-flyout-nav').addClass('l-flyout-nav__open');  
					$('.js-l-flyout-nav').attr('id', mainNavID2);
					$(this).addClass('l-pagetree-main__item--open'); 
					
				}
			} else {
				$('.js-l-flyout-nav').addClass('l-flyout-nav__open');  
				$('.js-l-flyout-nav').attr('id', mainNavID2);
				$(this).addClass('l-pagetree-main__item--open');
			}

			return false;
		});

		$('.js-l-page__content').on('click touchend', function (e) {
			$('.js-l-flyout-nav').removeClass('l-flyout-nav__open'); 
			$('.js-l-flyout-nav__opener').removeClass('l-pagetree-main__item--open');
		});
	}



	/**
	 * Function closeNav():void
	 * 
	**/

	protected closeNav():void {
		$('.js-l-flyout-nav').removeClass('l-flyout-nav__open'); 
		$('.js-l-flyout-nav__opener').removeClass('l-pagetree-main__item--open'); 
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the FlyoutNav class
	**/

	protected watchEvents():void {

		/**
		 * Initalise function variables 
		**/

		let self = this;


		/**
		 * Call functions
		**/

		/* Toogle navigation */

		self.toggleNav();


		/* Close menu on resize & scroll */

		$(window).scroll(() => {
			this.closeNav();
		});
		
		$(window).on('resize',function () {
			$('.js-l-flyout-nav').removeClass('l-flyout-nav__open'); 
			$('.js-l-flyout-nav__opener').removeClass('l-pagetree-main__item--open'); 
		});
	}
}
/**
 * pageloader.ts
 * Logic of pageloader (spinner)
 * 
**/

class pageLoader {

   /**
     * Define class variables
    **/

   public maximumTimeVisible:number; 



    /**
     * Constructor
    **/

    constructor (maximumTimeVisible: number=3000) {

        /**
         * Initalise local constructor variables
        **/

        let self = this;



        /**
         * Set parameters
        **/

        self.maximumTimeVisible = maximumTimeVisible;



        /**
         * Call functions
        **/

        /* Hide loader after page is fully loaded */
         $(window).on('load', function(){
            self.hideLoader();
        });

        /* Hide loader after maximumtime if page is loading too long */
        setTimeout(function() {
            self.hideLoader();   
        }, self.maximumTimeVisible);
    }



    /**
     * Function hideLoader():void
     * hide spinner after page is fully loaded
    **/

    protected hideLoader():void {
         $('.js-l-pageloader').fadeOut(100); 
    }
}
/**
 * navigation.ts
 * Logic of navigation
**/



class Panelnav {

    /**
     * Initalise class variables
    **/

    public scrollbarWidth:any;
    

    
    /**
     * Constructor
    **/

    constructor (accordionMode:boolean=true,openCurrent:boolean=true,animationDuration: number=1000,overviewpagesPossible :boolean=false) {

        /**
         * Initalise local constructor variables
        **/
       
        let self = this;



        /**
         * Call functions
        **/

        self.measureScrollbar();
        self.watchEvents();



        /**
         * Get instance of panelmenu
        **/

        let pagetreePanel = new PagetreePanel(accordionMode, openCurrent, animationDuration, overviewpagesPossible);
    }


    
    /*
    * Function measureScrollbar():void
    * Measures scrollbar width of browser and adds a div 
    * that prevents jumping of content
    */

    public measureScrollbar():void {

        /**
         * Initalise function variables 
        **/

        let self = this;
        let scrollDiv = document.createElement("div");
        scrollDiv.className = "scrollbar-measure";
        document.body.appendChild(scrollDiv);
        self.scrollbarWidth = window.innerWidth - scrollDiv.clientWidth;
        document.body.removeChild(scrollDiv);
    }



    /**
     * Function watchEvents():void
     * Watches all events of the navigation class
    **/

    public watchEvents():void {

        /**
         * Initalise function variables 
        **/

        let self = this;



        /**
         * Toggle bodyclass 'js-l-panelmenu--open' 
        **/

		$('.js-l-pagetree-panel__opener, .js-l-panel-nav__backdrop, .js-panel-nav__close').on('click touchend', function (e) {
			e.preventDefault();
			if($('.js-l-body').hasClass('js-l-panel-nav--open')) {
				$('.js-l-body').removeClass('js-l-panel-nav--open').css({marginRight: '0px'});
				$('.l-header__wrapper').css({paddingRight: '0px'});
			} else {
				$('.js-l-body').addClass('js-l-panel-nav--open').css({marginRight: self.scrollbarWidth + 'px'});
				$('.l-header__wrapper').css({paddingRight: self.scrollbarWidth + 'px'});
			}
			// $('html').toggleClass('openNavi');
			$('.js-l-panel-nav__backdrop').fadeToggle();
		});
    }
}
/**
 * sidebar.ts
 * 
 * 
**/

class Sidebar {

	/**
	 * Define class variables
	**/


	/**
	 * Constructor
	**/

	constructor () {
		
		/**
		 * Initalise function variables 
		**/

		let self = this;



		/**
		 * Call functions
		**/

		/* Watch events */

		self.watchEvents();
	}



	/*
	* Function openSidebar
	* opens sidebar on click
	*/

	protected openSidebar():void {
		$('.l-sidebar__icon').on('click', function() {
			$('.l-sidebar__item').not($(this).parent()).removeClass('open');
			$(this).parent('.l-sidebar__item').toggleClass('open'); 
			
		});
	}



	/*
	* Function hideSidebar
	* hides sidebar on click
	*/

	protected hideSidebar():void {
		let windowHeight = $(window).height();

		if(windowHeight <= 500) {
			$(".l-sidebar").hide();
		} else {
			$(".l-sidebar").show();
		}
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the navigation class
	**/

	protected watchEvents():void {

		/**
		 * Initalise function variables
		**/

		let self = this;



		/**
		 * Call functions
		**/

		self.openSidebar();
		self.hideSidebar();
		

		/* Hide sidebar on resize */

		$(window).on('resize',function () {
			self.hideSidebar();
		});
	}
}
/**
 * to-top.ts
 * Logic of to-top button on page end
 * 
**/

class toTop {

	/**
	 * Define class variables
	**/

	public animationDuration:number; 



	/**
	 * Constructor
	**/

	constructor (animationDuration: number=900) {

		/**
		 * Initalise local constructor variables
		**/

		let self = this;



		/**
		 * Set parameters
		**/

		self.animationDuration = animationDuration;



		/**
		 * Call functions
		**/

		this.checkVisibility();
		this.watchEvents();
	}



	/**
	 * Function initAction():void
	 * set/reset to-top classes
	**/

	protected checkVisibility():void {
		if($(window).scrollTop() > 9)
			$('.js-l-to-top').addClass('l-to-top--active');
		else 
			$('.js-l-to-top').removeClass('l-to-top--active');
	}



	/**
	 * Function watchEvents():void
	 * Watches all events of the navigation class
	**/

	protected watchEvents():void {

		/**
		 * Initalise function variables 
		**/

		let self = this;



		/**
		 * Toggle bodyclass 'js-l-to-top--visible' 
		 * if page is scrolled
		**/

		$(window).on('scroll',function () {
			self.checkVisibility();
		});


		
		/**
		 * Animate HTML to top 
		**/
		
		$('.js-l-to-top').on('click',function(event) {
			$('body,html').stop().animate({ 
				scrollTop: 0}, self.animationDuration);
			event.preventDefault();
		}); 
	}
}